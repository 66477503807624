import 'external-svg-loader';
import Measure from 'react-measure';
import * as React from 'react';
import {
  useState,
  useCallback,
  ReactElement,
  SyntheticEvent,
  useEffect,
} from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import { HelpOutline, Share } from '@material-ui/icons';
import { ClickAwayListener, makeStyles } from '@material-ui/core';
import {
  AppHeader,
  Container,
  CAPTION,
  IconPlaceHolder,
} from '@tuunetech/tuune-components';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { TopMoon, BottomMoon } from '../components/HalfMoons';
import { HowItWorks } from '../components/HowItWorks';
import GENERICS from '../data/generics.json';
import BENEFITS from '../data/benefits.json';
import PILLS from '../data/pills.json';
import { PillDetails } from '../components/PillDetails';
import { Landing } from '../components/Landing';
import { SymptomIconSvg } from '../components/SymptomIcon';
import { PILL_TYPE_MAPPINGS } from '../constants';
import { SharePopup } from '../components/SharePopup';
import { trackEvent } from '../utils/analytics/amplitude';
import {
  TUUNE_LOGO_CLICK,
  HOW_DOES_THIS_WORK_OPEN,
  NATIVE_SHARE,
  PILL_SELECTED,
  SYMPTOMS_SELECTED,
  SYMPTOM_BENEFIT_OPEN,
  SNIPPET_FEEDBACK_BUTTON_CLICK,
  SHARE_BUTTON_CLICKED,
  SELECTED_PILL_IMPACTS_PERCENTAGE_DISTRIBUTION,
} from '../utils/analytics/constants';
import {
  BenefitsJsonType,
  DataQueryType,
  GenericsJsonType,
  Issue,
  PillsJsonType,
  PillType,
} from 'types';
import { useFrameMode } from '../utils/hooks/useFrameMode';
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    height: '100%',
  },
  caption: {
    color: theme.palette.common.grey[700],
  },
}));

const FrameContainer = styled.div<{ $isPillCardShowing: boolean }>`
  padding: 56px 0;

  background-color: #FFF7F5;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ $isPillCardShowing }) =>
      $isPillCardShowing ? '56px 0' : '106px 0'};
  }
`;

declare global {
  interface Navigator {
    canShare?: (data?: ShareData) => boolean;
  }
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    amplitude: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

const isBrowser = typeof window !== 'undefined';

// markup
const IndexPage: React.FC<PageProps> = () => {
  const [isHowItWorksOpen, setIsHowIsWorksOpen] = useState(false);
  const [selectedPill, setSelectedPill] = useState<PillType | null>(null);
  const [selectedIssues, setSelectedIssues] = useState<Issue[]>([]);
  const [isPillCardShowing, setIsPillCardShowing] = useState(false);

  const classes = useStyles();
  const [contentHeight, setContentHeight] = useState(1);

  const [isFrameMode, sendResizeMessage] = useFrameMode();

  const shareData = {
    title: 'Tuune - Check your contraceptive pill',
    text: 'Learn the effects your current contraceptive pill may have on different symptoms',
    url: isBrowser ? window.location.href : '',
  };

  const data = useStaticQuery<DataQueryType>(graphql`
    query Query {
      allContentfulIssue(filter: { node_locale: { eq: "en-US" } }) {
        nodes {
          content {
            file {
              url
            }
          }
          slug
        }
      }
      allContentfulContraceptionDetailPersonalised(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        nodes {
          content {
            raw
          }
          slug
        }
      }
    }
  `);
  const [shareAnchorEl, setShareAnchorEl] = useState<Element | null>(null);

  const onImpactDropdownCallback = useCallback(
    (
      recommendationName: string,
      benefitDetails?: { benefit: string; impact: string },
    ) => {
      benefitDetails &&
        trackEvent(SYMPTOM_BENEFIT_OPEN, {
          pill: recommendationName,
          benefit: benefitDetails,
        });
    },
    [],
  );
  const getRawCaption = (issueSlug: string, pillSlug: string) => {
    return data?.allContentfulContraceptionDetailPersonalised?.nodes?.find(
      node => node.slug === `${issueSlug}-${pillSlug}`,
    )?.content?.raw;
  };

  const renderOptions: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children): ReactElement => {
        return <CAPTION className={classes.caption}>{children}</CAPTION>;
      },
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <a
          href={data.uri}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.caption}
        >
          {children}
        </a>
      ),
    },
  };

  useEffect(() => {
    if (isPillCardShowing && selectedPill && selectedIssues.length) {
      const impactFrequencies: Record<string, number> = {};
      const BENEFITS_JSON: BenefitsJsonType = BENEFITS;

      // Calculate number of occurences for each impact
      selectedIssues.forEach(issue => {
        const impact = BENEFITS_JSON[selectedPill.slug][issue.slug];
        if (impactFrequencies[impact]) {
          impactFrequencies[impact]++;
        } else {
          impactFrequencies[impact] = 1;
        }
      });

      const totalIssues = selectedIssues.length;
      Object.keys(impactFrequencies).forEach(key => {
        // Calculate the raw percentage value
        const percentValue = (impactFrequencies[key] / totalIssues) * 100;
        // Round to 2 decimal places
        impactFrequencies[key] = Math.round(percentValue * 100) / 100;
      });
      trackEvent(SELECTED_PILL_IMPACTS_PERCENTAGE_DISTRIBUTION, {
        ...impactFrequencies,
      });
    }
  }, [selectedPill, selectedIssues, isPillCardShowing]);

  const formattedPillData = useCallback(() => {
    if (selectedPill) {
      const PILLS_JSON: PillsJsonType = PILLS;
      const GENERICS_JSON: GenericsJsonType = GENERICS;
      const BENEFITS_JSON: BenefitsJsonType = BENEFITS;

      const oestrogenIngredients = `${
        PILLS_JSON[selectedPill.name].oestrogen
      }(${PILLS_JSON[selectedPill.name].oestrogen_dose})`;
      const progestinIngredients = `${
        PILLS_JSON[selectedPill.name].progestin
      }(${PILLS_JSON[selectedPill.name].progestin_dose})`;
      const pillType = PILLS_JSON[selectedPill.name].type;
      return [
        {
          name: selectedPill.name,
          generics: GENERICS_JSON[selectedPill.slug],
          ingredients:
            pillType === 'COC'
              ? [oestrogenIngredients, progestinIngredients]
              : [progestinIngredients],
          type: PILL_TYPE_MAPPINGS[selectedPill.type],
          benefits: selectedIssues.map(issue => {
            const impact = BENEFITS_JSON[selectedPill.slug][issue.slug];
            const rawCaption = getRawCaption(issue.slug, selectedPill.slug);
            const captionLength = rawCaption
              ? documentToPlainTextString(JSON.parse(rawCaption)).length
              : 0;
            const caption = rawCaption
              ? {
                  node: documentToReactComponents(
                    JSON.parse(rawCaption),
                    renderOptions,
                  ) as unknown as ReactElement,
                  textLength: captionLength,
                }
              : undefined;
            return {
              name: issue.name,
              slug: issue.slug,
              impact: impact,
              caption: caption,
            };
          }),
          onBenefitDropdownClick: (benefitDetails?: {
            benefit: string;
            impact: string;
          }) => onImpactDropdownCallback(selectedPill.name, benefitDetails),
          onFeedbackButtonClick: (
            clear: boolean,
            issue: string,
            pill: string,
          ) =>
            trackEvent(SNIPPET_FEEDBACK_BUTTON_CLICK, {
              issue: issue,
              pill: pill,
              clear: clear,
            }),
        },
      ];
    }
    return [];
  }, [selectedPill, selectedIssues]);

  const benefitList = selectedIssues.map(issue => {
    const iconUrl = data?.allContentfulIssue?.nodes?.find(
      node => node.slug === issue.slug,
    )?.content?.file?.url;
    return {
      name: issue.name,
      slug: issue.slug,
      icon: iconUrl ? (
        <SymptomIconSvg data-src={`https:${iconUrl}`} />
      ) : (
        <IconPlaceHolder />
      ),
    };
  });

  const handleTuuneAppRedirect = () => {
    trackEvent(TUUNE_LOGO_CLICK, {});
    window.open('https://www.tuune.com/', '_blank');
  };
  const handleHowItWorksOpen = () => {
    trackEvent(HOW_DOES_THIS_WORK_OPEN, {});
    setIsHowIsWorksOpen(true);
  };
  const handleHowItWorksClose = () => {
    setIsHowIsWorksOpen(false);
  };
  const handleReset = () => {
    setSelectedPill(null);
    setSelectedIssues([]);
    setIsPillCardShowing(false);
  };
  const handlePillSelected = (pill: PillType) => {
    trackEvent(PILL_SELECTED, { pill: pill });
    setSelectedPill(pill);
  };
  const handleIssuesSelected = (issues: Issue[]) => {
    trackEvent(SYMPTOMS_SELECTED, { issues: issues });
    setSelectedIssues(issues);
  };

  const handleShare = (e: SyntheticEvent) => {
    trackEvent(SHARE_BUTTON_CLICKED, {});
    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator
        .share(shareData)
        .then(() => {
          trackEvent(NATIVE_SHARE, {});
          // shared successfully
        })
        .catch(() => {
          //error while sharing: NotAllowedError, TypeError, AbortError, DataError
        });
    } else {
      console.log('handle sharing differently');
      setShareAnchorEl(e.currentTarget);
    }
  };

  const content = (
    <>
      {isPillCardShowing ? (
        <PillDetails
          formattedPillData={formattedPillData()}
          benefitList={benefitList}
          handleResetCallback={handleReset}
        />
      ) : (
        <Landing
          setSelectedIssuesCallback={handleIssuesSelected}
          setSelectedPillCallback={handlePillSelected}
          setIsPillCardShowingCallback={isShowing =>
            setIsPillCardShowing(isShowing)
          }
          selectedPill={selectedPill}
          selectedIssues={selectedIssues}
        />
      )}
    </>
  );

  useEffect(() => {
    sendResizeMessage(contentHeight);
  }, [contentHeight]);

  return isFrameMode ? (
    <Measure
      bounds
      onResize={contentRect => {
        setContentHeight(contentRect.bounds?.height || 1);
      }}
    >
      {({ measureRef }) => (
        <FrameContainer ref={measureRef} $isPillCardShowing={isPillCardShowing}>
          <Container disableGutters maxWidth="md">
            {content}
          </Container>
        </FrameContainer>
      )}
    </Measure>
  ) : (
    <Container fixed={false} className={classes.root}>
      <ClickAwayListener onClickAway={() => setShareAnchorEl(null)}>
        <div>
          <AppHeader
            color="darkTransparent"
            colorOnScroll="inherit"
            handleStartAdornmentClick={handleTuuneAppRedirect}
            buttons={[
              {
                title: 'How does this work?',
                outOfKebabIcon: <HelpOutline />,
                onClick: handleHowItWorksOpen,
              },
              {
                title: 'Share',
                outOfKebabIcon: <Share />,
                onClick: handleShare,
              },
            ]}
          />
          <SharePopup open={!!shareAnchorEl} anchorEl={shareAnchorEl} />
        </div>
      </ClickAwayListener>
      <HowItWorks
        open={isHowItWorksOpen}
        onClose={handleHowItWorksClose}
        onOpen={handleHowItWorksOpen}
      />
      <Container fixed={false} className={classes.content}>
        <TopMoon />
        {content}
        <BottomMoon />
      </Container>
    </Container>
  );
};

export default IndexPage;
