import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  moonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  topMoon: {
    marginTop: '0',
  },
  bottomMoon: {
    marginBottom: '0',
  },
  moon: {
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
}));
