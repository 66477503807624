export const TUUNE_LOGO_CLICK = 'Tuune logo in header click';
export const HOW_DOES_THIS_WORK_OPEN = 'How does this work modal open';
export const NATIVE_SHARE = 'Checkmypill is shared via native panel';
export const SHARE_BUTTON_CLICKED = 'Share button clicked';
export const LINK_COPIED = 'Link to checkmypill was copied';
export const CHECK_PILL_CTA_CLICK = 'Check my pill CTA click';
export const PILL_SELECTED = 'Pill selected';
export const SYMPTOMS_SELECTED = 'Symptoms selected';
export const SYMPTOM_BENEFIT_OPEN = 'Symptom benefit open';
export const CHECK_OTHER_PILL_CLICK = 'Check another pill CTA click';
export const GO_TO_APP_CTA_CLICK = 'Go to Tuune app CTA click';
export const SNIPPET_FEEDBACK_BUTTON_CLICK =
  'Issue snippet feedback button click';
export const SELECTED_PILL_IMPACTS_PERCENTAGE_DISTRIBUTION = 'Selected pill impacts percentage distribution';
