import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useStyles } from './styles';

export const TopMoon: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.moonWrapper} ${classes.topMoon}`}>
      <StaticImage
        src="../../images/top_moon.png"
        alt=""
        placeholder="tracedSVG"
        className={classes.moon}
        objectFit="fill"
      />
    </div>
  );
};

export const BottomMoon: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.moonWrapper} ${classes.bottomMoon}`}>
      <StaticImage
        src="../../images/bottom_moon.png"
        alt=""
        placeholder="tracedSVG"
        className={classes.moon}
        objectFit="fill"
      />
    </div>
  );
};
