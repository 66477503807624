import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PillType, Issue } from 'types';
import { trackEvent } from '../../utils/analytics/amplitude';
import { CHECK_PILL_CTA_CLICK } from '../../utils/analytics/constants';
import {
  EmailRegistrationFormData,
  emailRegistrationSchema,
} from '../../constants/validation';
import { localStorageKeys, MARKETING } from '../../constants';
import { externalEmailRegistration } from '../../utils/api/requests';
import { useFrameMode } from '../../utils/hooks/useFrameMode';
import { getFromStorage, setToStorage } from '../../utils/localStorage';
import { Selectors } from '../Selectors';

type LandingProps = {
  setSelectedIssuesCallback: (issues: []) => void;
  setSelectedPillCallback: (pill: PillType) => void;
  setIsPillCardShowingCallback: (showing: boolean) => void;
  selectedPill: PillType | null;
  selectedIssues: Issue[];
};

export const Landing: React.FC<LandingProps> = ({
  setSelectedIssuesCallback,
  setSelectedPillCallback,
  setIsPillCardShowingCallback,
  selectedPill,
  selectedIssues,
}) => {
  const [isFrameMode] = useFrameMode();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState<boolean>(false);

  const checkEmailSubmitted = async () => {
    const isEmailSubmitted = await getFromStorage(
      localStorageKeys.EXTERNAL_REGISTRATION_SUBMITTED,
    );
    if (isEmailSubmitted) {
      setIsEmailSubmitted(true);
    }
  };

  useEffect(() => {
    if (isFrameMode) {
      setIsEmailSubmitted(true);
    } else {
      checkEmailSubmitted();
    }
  }, [isFrameMode]);

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm<EmailRegistrationFormData>({
    resolver: yupResolver(emailRegistrationSchema),
    mode: 'onTouched',
    defaultValues: {
      email: '',
      [MARKETING.marketingPreference]: false,
    },
  });

  const formSubmitCallback = async (fieldValues: FieldValues) => {
    try {
      await externalEmailRegistration(
        fieldValues['email'],
        !!fieldValues[MARKETING.marketingPreference],
      );
      setToStorage(localStorageKeys.EXTERNAL_REGISTRATION_SUBMITTED, true);
      setIsPillCardShowingCallback(true);
    } catch (error) {
      setErrorMessage('Ooops! Something went wrong. Try again.');
    }
  };

  const submitCallback = useCallback(
    (e?: React.BaseSyntheticEvent) => {
      trackEvent(CHECK_PILL_CTA_CLICK, {});
      if (isEmailSubmitted) {
        setIsPillCardShowingCallback(true);
      } else {
        handleSubmit(formSubmitCallback)(e);
      }
    },
    [
      trackEvent,
      handleSubmit,
      formSubmitCallback,
      setIsPillCardShowingCallback,
      isEmailSubmitted,
    ],
  );

  const selectorsComponent = (
    <Selectors
      setSelectedIssuesCallback={setSelectedIssuesCallback}
      setSelectedPillCallback={setSelectedPillCallback}
      isSubmitButtonDisabled={
        !selectedPill ||
        !selectedIssues.length ||
        (!isEmailSubmitted && !isValid)
      }
      submitCallback={submitCallback}
      errorMessage={errorMessage}
      showEmailRegistrationForm={!isEmailSubmitted}
      control={control}
      errors={errors}
      triggerValidation={trigger}
    />
  );

  return isEmailSubmitted ? (
    <form onSubmit={handleSubmit(formSubmitCallback)}>
      {selectorsComponent}
    </form>
  ) : (
    selectorsComponent
  );
};
