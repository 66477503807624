import React, { useCallback, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { BODY1, Divider, Select } from '@tuunetech/tuune-components';
import SYMPTOMS from '../../data/issues.json';
import { ListSubheader, Checkbox, Values } from './styles';
import { Issue, SymptomsJsonType } from 'types';

type SymptomsSelectProps = {
  selectIssuesCallback: (issues: []) => void;
};

export const SymptomsSelect: React.FC<SymptomsSelectProps> = ({
  selectIssuesCallback,
}) => {
  const [selectedIssues, setSelectedIssues] = useState<Issue[]>([]);

  const onIssueSelection = useCallback(e => {
    const issues = e.target.value?.filter(
      (value: unknown) => value !== undefined,
    );
    setSelectedIssues(issues);
    selectIssuesCallback(issues);
  }, []);

  return (
    <Select
      multiple
      value={selectedIssues}
      onChange={onIssueSelection}
      displayEmpty
      renderValue={values => {
        const valuesToRender = values as unknown as Issue[];
        if (valuesToRender.length === 0) {
          return (
            <BODY1 color="textSecondary">What symptoms do you have?</BODY1>
          );
        }
        return (
          <Values>{valuesToRender.map(({ name }) => name).join(', ')}</Values>
        );
      }}
      fullWidth
    >
      {Object.keys(SYMPTOMS).map((issueGroup, idx, symptomGroups) => {
        const issues = (SYMPTOMS as SymptomsJsonType)[issueGroup];
        return [
          <ListSubheader>{issueGroup}</ListSubheader>,
          issues.map(issue => (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <MenuItem key={issue.slug} value={issue as any}>
              {issue.name}
              <Checkbox
                checked={
                  selectedIssues.findIndex(
                    selIssue => selIssue.slug === issue.slug,
                  ) !== -1
                }
                color="primary"
              />
            </MenuItem>
          )),
          idx !== symptomGroups.length - 1 ? (
            <Divider $verticalMargin={7} />
          ) : undefined,
        ];
      })}
    </Select>
  );
};
