import React, { useState } from 'react';
import {
  Box,
  Paper,
  Popper,
  PopperProps,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Button, Container, H3 } from '@tuunetech/tuune-components';
import { Input } from './styles';
import { trackEvent } from '../../utils/analytics/amplitude';
import { LINK_COPIED } from '../../utils/analytics/constants';

export const SharePopup: React.FC<Omit<PopperProps, 'children'>> = ({
  open,
  anchorEl,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleCopy = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setIsCopied(true);
        trackEvent(LINK_COPIED, {});
      })
      .catch(() => console.log('not copied'));
  };

  return (
    <Popper open={open} anchorEl={anchorEl}>
      <Paper elevation={8}>
        <Container $horizontalPadding={16} $verticalPadding={16}>
          <H3 paragraph>Share this site</H3>
          <Box
            display="flex"
            alignItems="center"
            flexWrap={isDesktop ? 'nowrap' : 'wrap'}
          >
            <Input value="checkmypill.tuune.com" readOnly />
            <Tooltip
              title={isCopied ? 'Copied!' : 'Copy to clipboard'}
              placement="top"
            >
              <Box marginTop={isDesktop ? 0 : '16px'}>
                <Button
                  tabIndex={0}
                  onClick={handleCopy}
                  onMouseEnter={() => setIsCopied(false)}
                >
                  copy
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Container>
      </Paper>
    </Popper>
  );
};
