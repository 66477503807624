import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';
import { BODY1, Select } from '@tuunetech/tuune-components';
import PILLS from '../../data/pills.json';
import { PillsJsonType, PillType } from 'types';

type PillSelectProps = {
  selectPillCallback: (pill: PillType) => void;
};

const pills: PillType[] = Object.values(PILLS as PillsJsonType)
  .sort((a, b) => {
    const pillNameA = a.name;
    const pillNameB = b.name;
    return pillNameA < pillNameB ? -1 : pillNameA > pillNameB ? 1 : 0;
  })
  .map((pill: PillType) => {
    return { name: pill.name, slug: pill.slug, type: pill.type };
  });

export const PillSelect: React.FC<PillSelectProps> = ({
  selectPillCallback,
}) => {
  const onPillSelection = useCallback(e => {
    const pillName = e.target.value;
    const pill = pills.find(pill => pill.name === pillName);
    if (pill) {
      selectPillCallback(pill);
    }
  }, []);

  return (
    <Select
      defaultValue=""
      onChange={onPillSelection}
      displayEmpty
      renderValue={value => {
        const valueToRender = value as unknown as PillType;
        if (value === '') {
          return <BODY1 color="textSecondary">Select pill</BODY1>;
        }
        return valueToRender;
      }}
      fullWidth
    >
      {pills.map(({ name }) => {
        return (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        );
      })}
    </Select>
  );
};
