import { AxiosRequestConfig, AxiosPromise } from 'axios';
import { MARKETING } from '../../constants';
import { apiRequest, API_HOST } from './constants';

export const externalEmailRegistration = (
  email: string,
  acceptMarketingPreferences: boolean,
): Promise<AxiosPromise> => {
  const requestConfig: AxiosRequestConfig = {
    url: `${API_HOST}/api/v1/mailing/ext_email_registration/`,
    method: 'post',
    data: {
      email,
      [MARKETING.marketingPreference]: acceptMarketingPreferences,
      source: window.location.hostname,
    },
    headers: {},
  };

  return apiRequest(requestConfig);
};
