import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  text: {
    alignSelf: 'center',
    maxWidth: '800px',
  },
  caption: {
    color: theme.palette.common.grey[600],
  },
  tuuneTextDescription: {
    alignSelf: 'center',
    maxWidth: '320px',
  },
  cardAlignment: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    rowGap: '16px',
    maxWidth: '360px',
    margin: '0 auto 24px',
  },
  starIconWrapper: {
    width: '24px',
    height: '24px',
    marginRight: '5px',
  },
  starIcon: {
    fill: theme.palette.common.white,
  },
}));
