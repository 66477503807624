import axios from 'axios';
import { AxiosPromise, AxiosRequestConfig } from 'axios';

export const API_HOST = 'https://api.tuune.com';

const axiosInstance = axios.create({
  baseURL: API_HOST,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiRequest = async (
  config: AxiosRequestConfig,
): Promise<AxiosPromise> => await axiosInstance(config);
