import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  selectors: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  text: {
    alignSelf: 'center',
  },
  caption: {
    color: theme.palette.common.grey[600],
  },
}));
