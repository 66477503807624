import * as React from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import {
  Button,
  H2,
  CAPTION,
  BODY1,
  Container,
} from '@tuunetech/tuune-components';
import { SymptomsSelect } from '../SymptomsSelect';
import { PillSelect } from '../PillSelect';
import { PillType } from 'types';
import { useStyles } from './styles';
import {
  EmailRegistrationForm,
  EmailRegistrationFormProps,
} from '../EmailRegistrationForm';
import { FOOTER_TEXT_DESCRIPTION } from '../../constants';

type SelectorsProps = {
  setSelectedIssuesCallback: (issues: []) => void;
  setSelectedPillCallback: (pill: PillType) => void;
  submitCallback: () => void;
  isSubmitButtonDisabled: boolean;
  showEmailRegistrationForm: boolean;
  errorMessage: string | null;
} & EmailRegistrationFormProps;

export const Selectors: React.FC<SelectorsProps> = ({
  setSelectedIssuesCallback,
  setSelectedPillCallback,
  isSubmitButtonDisabled,
  submitCallback,
  errorMessage,
  showEmailRegistrationForm,
  control,
  errors,
  triggerValidation,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box className={classes.selectors}>
        <H2 className={classes.text}>
          Could your pill be impacting your symptoms?
        </H2>

        <Box height={isMobile ? '40px' : '48px'} />
        {errorMessage && (
          <BODY1 className={classes.text} $marginBottom={8} color="error">
            {errorMessage}
          </BODY1>
        )}
        <PillSelect
          selectPillCallback={pill => setSelectedPillCallback(pill)}
        />
        <Box height="8px" />
        <SymptomsSelect
          selectIssuesCallback={issues => setSelectedIssuesCallback(issues)}
        />
        {showEmailRegistrationForm && (
          <EmailRegistrationForm
            control={control}
            errors={errors}
            triggerValidation={triggerValidation}
          />
        )}
        <Box height="40px" />
        <Button
          disabled={isSubmitButtonDisabled}
          onClick={submitCallback}
          fullWidth={isMobile}
        >
          CHECK MY PILL
        </Button>
        <Box height={isMobile ? '40px' : '48px'} />
        <CAPTION className={`${classes.text} ${classes.caption}`}>
          {FOOTER_TEXT_DESCRIPTION}
        </CAPTION>
      </Box>
    </Container>
  );
};
