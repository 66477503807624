export const PILL_TYPE_MAPPINGS: Record<string, string> = {
  COC: 'Combined pill',
  POP: 'Mini-pill',
};

export enum MARKETING {
  marketingPreference = 'accept_marketing_preference',
}

export const localStorageKeys = {
  EXTERNAL_REGISTRATION_SUBMITTED: 'externalRegistrationSubmitted',
};

export const FOOTER_TEXT_DESCRIPTION = `Tuune's science team has curated published scientific studies to gather this information, but each individual is different and the effect a pill has on individuals may differ. Start your Tuune Health Assessment and find a better pill or talk to your doctor before stopping, starting, or switching contraception.`;
