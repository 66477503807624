import React, { ComponentProps } from 'react';
import { BODY1, DrawerModal, H4 } from '@tuunetech/tuune-components';
import { UnderlinedBODY1 } from './styles';

export const HowItWorks: React.FC<ComponentProps<typeof DrawerModal>> = ({
  open,
  onClose,
  onOpen,
}) => {
  return (
    <DrawerModal open={open} onClose={onClose} onOpen={onOpen}>
      <H4 paragraph>
        Our team of doctors and scientists has done an extensive review of the
        latest medical literature.
      </H4>
      <H4 $marginBottom={24}>
        We look for evidence of a pill’s impact on specific issues.
      </H4>
      <BODY1 color="textSecondary" paragraph>
        Large studies look at side effects associated with pills across the
        general population but each individual is different and this information
        should not be used to inform clinical decision making.
      </BODY1>
      <UnderlinedBODY1 color="textSecondary">
        We always encourage you to speak to your doctor before making any
        changes to your pill or regimen.
      </UnderlinedBODY1>
    </DrawerModal>
  );
};
