export const setToStorage = async (
  key: string,
  value: unknown,
): Promise<void> => {
  await localStorage.setItem(key, JSON.stringify(value));
};

export const getFromStorage = async (key: string): Promise<unknown> => {
  const value = await localStorage.getItem(key);
  if (value !== null) {
    return JSON.parse(value);
  } else {
    return null;
  }
};
