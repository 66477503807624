import * as React from 'react';
import {
  Controller,
  Control,
  FieldErrors,
  UseFormTrigger,
} from 'react-hook-form';
import { Box, Checkbox } from '@material-ui/core';
import { Input, ContentBlock, BODY1 } from '@tuunetech/tuune-components';
import { MARKETING } from '../../constants';
import { useStyles } from './styles';
import { EmailRegistrationFormData } from 'constants/validation';

export type EmailRegistrationFormProps = {
  control: Control<EmailRegistrationFormData>;
  errors: FieldErrors<EmailRegistrationFormData>;
  triggerValidation: UseFormTrigger<EmailRegistrationFormData>;
};

export const EmailRegistrationForm: React.FC<EmailRegistrationFormProps> = ({
  control,
  errors,
  triggerValidation,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box height="8px" />
      <Controller
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            fullWidth
            placeholder="Email"
            autoComplete="email"
            helperText={errors.email?.message}
            error={!!errors.email}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
        name="email"
        control={control}
      />
      <Box height="8px" />
      <ContentBlock
        className={classes.marketingContentBlock}
        startAdornment={
          <Controller
            name={MARKETING.marketingPreference}
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Checkbox
                id={MARKETING.marketingPreference}
                color="primary"
                onChange={(e) => {
                    onChange(e);
                    triggerValidation(MARKETING.marketingPreference);
                }}
                onBlur={onBlur}
                checked={value}
              />
            )}
          />
        }
        content={
          <BODY1
            component="label"
            htmlFor={MARKETING.marketingPreference}
            color="textSecondary"
            $marginBottom={16}
          >
            I want to receive the latest news and updates from Tuune.
          </BODY1>
        }
      />
    </>
  );
};
