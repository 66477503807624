import React from 'react';
import { Box } from '@material-ui/core';
import {
  Button,
  RecommendationTable,
  RecommendationTableProps,
  H2,
  CAPTION,
  Container,
  BODY1,
} from '@tuunetech/tuune-components';
import StarIcon from '../../images/svg/star_icon.inline.svg';
import { useStyles } from './styles';
import { trackEvent } from '../../utils/analytics/amplitude';
import {
  CHECK_OTHER_PILL_CLICK,
  GO_TO_APP_CTA_CLICK,
} from '../../utils/analytics/constants';
import { FOOTER_TEXT_DESCRIPTION } from '../../constants';

type PillDetailsProps = {
  formattedPillData: RecommendationTableProps['recommendations'];
  benefitList: RecommendationTableProps['benefitList'];
  handleResetCallback: () => void;
};

export const PillDetails: React.FC<PillDetailsProps> = ({
  formattedPillData,
  benefitList,
  handleResetCallback,
}) => {
  const handleTuuneAppRedirect = () => {
    trackEvent(GO_TO_APP_CTA_CLICK, {});
    window.open('https://www.tuune.com/birth-control', '_blank');
  };

  const handleCheckAnotherPillClick = () => {
    trackEvent(CHECK_OTHER_PILL_CLICK, {});
    handleResetCallback();
  };

  const classes = useStyles();

  return (
    <Container className={classes.cardAlignment} maxWidth="lg">
      <H2 className={classes.text} $marginBottom={24}>
        Could my pill be impacting my symptoms?
      </H2>
      <RecommendationTable
        recommendations={formattedPillData}
        benefitList={benefitList}
        addFeedbackButtons
        hideSidebar
        alignCenter
      />
      <Box height="24px" />
      <BODY1 className={`${classes.tuuneTextDescription}`} $marginBottom={40}>
        Tuune can help you find a birth control that works for you without
        unnecessary side effects.
      </BODY1>
      <BODY1 className={`${classes.tuuneTextDescription}`} $marginBottom={16}>
        Book an appointment with your doctor or use our online service for $29
        to get a better option.
      </BODY1>
      <Box className={classes.buttonContainer}>
        <Button
          onClick={handleTuuneAppRedirect}
          fullWidth={false}
          startIcon={
            <div className={classes.starIconWrapper}>
              <StarIcon className={classes.starIcon} />
            </div>
          }
        >
          Find a better pill with Tuune
        </Button>
        <Button
          onClick={handleCheckAnotherPillClick}
          fullWidth={false}
          variant="text"
        >
          CHECK ANOTHER PILL
        </Button>
      </Box>
      <CAPTION className={`${classes.text} ${classes.caption}`}>
        {FOOTER_TEXT_DESCRIPTION}
      </CAPTION>
    </Container>
  );
};
