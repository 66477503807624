import * as yup from 'yup';
import { MARKETING } from '.';

export const emailRegistrationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
  [MARKETING.marketingPreference]: yup.boolean().isTrue(),
});

export type EmailRegistrationFormData = {
  email: string;
  [MARKETING.marketingPreference]: boolean;
};
