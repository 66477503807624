import styled from 'styled-components';

export const SymptomIconSvg = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 4px;
  path {
    fill: ${({ theme }) => theme.palette.common.grey[600]};
  }
`;
