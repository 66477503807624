import { styled, Checkbox as MuiCheckbox } from '@material-ui/core';
import { H6 } from '@tuunetech/tuune-components';

export const ListSubheader = styled(H6)({
  padding: '13px 16px',
});

export const Checkbox = styled(MuiCheckbox)({
  marginLeft: 'auto',
});

export const Values = styled('div')({
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});
